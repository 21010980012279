<template>
  <div class="table">
    <div class="container">
      <div class="handle-box">
        <el-button type="success" icon="el-icon-plus" @click="onAdd">
          添加
        </el-button>
        <el-button type="danger" icon="el-icon-delete" @click="delAll">
          批量删除
        </el-button>
        <el-button icon="el-icon-refresh" @click="onRefresh">刷新</el-button>
      </div>
      <!--表格数据-->
      <el-table
        v-loading="listLoading"
        :data="list"
        element-loading-text="正在加载 ..."
        border
        fit
        highlight-current-row
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="角色名称" prop="name" />
        <el-table-column label="角色编号" prop="code" />
        <el-table-column label="描述" prop="description" width="300" />
        <el-table-column label="是否激活" prop="isActive">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isActive"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="changeSwitch($event, scope.row, scope.$index)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300" align="center">
          <template slot-scope="scope">
            <el-button
              type="success"
              size="small"
              @click="openMenuList(scope.row)"
            >
              分配角色菜单
            </el-button>
            <el-button type="primary" size="small" @click="onEdit(scope.row)">
              编辑
            </el-button>
            <el-button type="danger" size="small" @click="onDel(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="pageInfo.startPage"
          :page-sizes="[10, 20, 30, 50, 100, 200]"
          :page-size="pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.total"
          @size-change="onSizeChange"
          @current-change="onPageChange"
        />
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑信息" width="600px" :visible.sync="selectDlgShow">
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="角色名称" prop="name" required>
            <el-input v-model="form.name" type="text" clearable />
          </el-form-item>
          <el-form-item label="角色描述" prop="description">
            <el-input v-model="form.description" type="text" clearable />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="selectDlgShow = false">关 闭</el-button>
        <el-button type="info" plain @click="resetForm('form')">
          重 置
        </el-button>
        <el-button type="primary" @click="submitForm('form')">保存</el-button>
      </div>
    </el-dialog>

    <!--分配角色菜单对话框-->
    <el-dialog
      title="分配角色菜单"
      width="800px"
      :visible.sync="roleMenuDialog"
    >
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-input v-show="false" v-model="roleId"></el-input>
        <el-checkbox v-model="checked" @change="checkAll">
          全选/取消
        </el-checkbox>
        <el-tree
          ref="menuTree"
          :data="menuList"
          show-checkbox
          default-expand-all
          node-key="id"
          :check-strictly="true"
          highlight-current
          :default-checked-keys="defaultMenuList"
          :props="menuProps"
        >
        </el-tree>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="roleMenuDialog = false">关 闭</el-button>
        <el-button type="primary" @click="assignedMenu">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRoleList,
  saveRole,
  delRole,
  assignedRoleMenu,
  getRoleMenuList
} from '@/api/app/appRole';
import { getMenuList } from '@/api/app/appMenu';
import { Message, MessageBox } from 'element-ui';
import { constants } from 'fs';

export default {
  data() {
    return {
      pageInfo: {
        startPage: 1,
        pageSize: 20,
        total: 0
      },
      list: [],
      form: {
        id: '',
        name: '',
        description: ''
      },
      menuList: [],
      defaultMenuList: [],
      menuProps: {
        label: 'title',
        children: 'childrenList',
        id: 'id'
      },
      roleId: 0,
      checked: false,
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      roleMenuDialog: false,
      multipleSelection: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    checkAll() {
      //全选
      if (this.checked == true) {
        this.$refs.menuTree.setCheckedNodes(this.menuList);
      } else {
        this.$refs.menuTree.setCheckedKeys([]);
      }
    },
    onRefresh() {
      this.fetchData();
    },
    onAdd() {
      this.form = {
        id: '',
        name: '',
        description: ''
      };
      this.selectDlgShow = true;
    },
    onEdit(row) {
      this.form = {
        id: row.id,
        name: row.name,
        description: row.description
      };
      this.selectDlgShow = true;
    },
    delAll() {
      this.$confirm('您确定要删除所选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var ids = [];
        this.multipleSelection.forEach(item => {
          ids.push(item.id);
        });
        delRole({ ids: ids })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    onDel(row) {
      this.$confirm('此操作将永久删除尺寸配置数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delRole({ id: row.id })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    fetchData() {
      this.listLoading = true;
      getRoleList(this.pageInfo).then(response => {
        this.list = response.data.records;
        this.pageInfo.total = response.data.total;
        this.pageInfo.startPage = response.data.current;
        this.pageInfo.pageSize = response.data.size;
        this.listLoading = false;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.save();
        } else {
          return false;
        }
      });
    },
    save() {
      this.dlgLoading = true;
      saveRole(this.form)
        .then(resp => {
          if (resp.code == 200) {
            this.$message({
              message: resp.msg,
              type: 'success'
            });
            this.selectDlgShow = false;
            this.dlgLoading = false;
            this.fetchData();
          } else {
            this.$message.error(resp.msg);
          }
        })
        .catch(() => {
          this.dlgLoading = false;
        });
    },
    openMenuList(row) {
      //打开菜单列表
      this.defaultMenuList = [];
      this.checked = false;
      this.roleId = row.id;
      getMenuList({ startPage: 1, pageSize: 30 }).then(resp => {
        this.menuList = resp.data;
      });
      //默认选择项
      getRoleMenuList({ id: this.roleId }).then(resp => {
        let menuList = resp.data.menuList;
        var ids = [];
        menuList.forEach(item => {
          ids.push(item.id);
        });
        this.defaultMenuList = ids;
      });
      this.roleMenuDialog = true;
    },
    assignedMenu() {
      //分配角色菜单
      let menus = this.$refs.menuTree.getCheckedNodes();
      var menuid = [];
      menus.forEach(item => {
        menuid.push(item.id);
      });
      assignedRoleMenu({ roleid: this.roleId, menuid: menuid }).then(resp => {
        if (resp.code == 200) {
          this.$message({
            message: '分配成功',
            type: 'success'
          });
          this.roleMenuDialog = false;
        } else {
          this.$message.error('分配失败');
        }
      });
    },
    changeSwitch(data, b, index) {
      //开关切换
      saveRole(b).then(resp => {
        if (resp.code == 200) {
          this.$message({
            message: '状态修改成功',
            type: 'success'
          });
        } else {
          this.$message.error(resp.msg);
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}

.table {
  width: 100%;
  font-size: 14px;
}
.el-tree-node__label {
  margin-left: 5px !important;
}
</style>
